var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0 pb-10",attrs:{"color":"primary","icon":"mdi-file-tree","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Logs da importação #"+_vm._s(_vm.importacao.id)+" ")])]},proxy:true}])},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mt-n9",staticStyle:{"float":"right !important"}},'div',attrs,false),on),[_c('v-btn',_vm._g(_vm._b({attrs:{"id":"importacao-dados-logs-tecnicos","icon":"","color":"black","loading":_vm.loadingLogsTecnicos},on:{"click":function($event){return _vm.baixarLogsTecnicos()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" mdi-download-circle-outline ")])],1)],1)]}}])},[_vm._v(" Clique aqui para baixar os logs técnicos ")]),_c('v-divider',{staticClass:"my-4"}),_c('span',{attrs:{"id":"importacao-dados-logs-first-sec"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"d-block mb-1"},[_vm._v(" Título da importação: ")]),_c('v-label',[_vm._v(_vm._s(_vm.importacao.titulo))])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"d-block mb-1"},[_vm._v(" Empresa: ")]),_c('v-label',[_vm._v(_vm._s(_vm.importacao.empresa))])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"d-block mb-1"},[_vm._v(" Descrição: ")]),_c('v-label',[_vm._v(_vm._s(_vm.importacao.descricao ? _vm.importacao.descricao : '-'))])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"d-block mb-1"},[_vm._v(" Arquivo: ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"clickable-item",staticStyle:{"display":"inline !important"},attrs:{"id":"importacao-dados-baixar-arquivo"},on:{"click":function($event){return _vm.baixarArquivo()}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.importacao.arquivo)+" ("+_vm._s(_vm.importacao.encoding)+") "),(_vm.loading)?_c('v-progress-circular',{attrs:{"size":"20","indeterminate":"","color":"rgba(0, 0, 0, 0.87)"}}):_vm._e()],1)]}}])},[_vm._v(" Clique aqui para baixar o arquivo "+_vm._s(_vm.importacao.arquivo)+" ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"d-block mb-1"},[_vm._v(" Tipo de importação: ")]),_c('v-label',[_vm._v(_vm._s(_vm.importacao.tipo))])],1),(_vm.importacao.data_registro)?_c('v-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"d-block mb-1"},[_vm._v(" Mês dos dados: ")]),_c('v-label',[_vm._v(" "+_vm._s(_vm._f("formatToMonth")(_vm.importacao.data_registro))+" ")])],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"d-block mb-1"},[_vm._v(" Criado por: ")]),_c('v-label',[_vm._v(_vm._s(_vm.importacao.usuario)+" em "+_vm._s(_vm.importacao.criado_em))])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('label',{staticClass:"d-block mb-1"},[_vm._v(" Logs da importação: ")]),(_vm.importacao.log_importacao_existe)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"importacao-dados-logs-valid","color":"gray","outlined":"","small":"","loading":_vm.loadingLogs},on:{"click":function($event){return _vm.baixarLogs()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-download-circle-outline ")]),_vm._v(" Baixar ")],1)]}}],null,false,3846854270)},[_vm._v(" Clique aqui para baixar os logs ")]):_c('v-label',[_vm._v("-")])],1)],1)],1),_c('v-divider',{staticClass:"mt-2 mb-2"}),(_vm.importacao.status)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('import-status-card',{attrs:{"id":"import-status-card","status":_vm.importacao.status,"contadores":_vm.contadores}})],1)],1):_vm._e(),(_vm.importacao.status)?_c('v-row',{attrs:{"id":"import-status-tabela-contadores"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('tabela-contadores',{attrs:{"contadores":_vm.contadores}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }